import * as React from 'react';
import cx from 'classnames';

import * as cs from './MarkdownHeading.module.css';

interface Props {
  children?: React.ReactNode;
  className?: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

export const MarkdownHeading = ({
  children,
  className,
  level,
  ...restProps
}: Props) => {
  const Component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = `h${level}`;

  return (
    <Component className={cx(cs.root, className)} {...restProps}>
      {children}
    </Component>
  );
};
