import * as React from 'react';
import cx from 'classnames';

import { Icon } from '../Icon/Icon';

import * as cs from './FactSheet.module.css';

interface Props {
  fact: CustomTypes.Fact | null;
  className?: string;
  headerLevel?: 2 | 3;
}

export const FactSheet = ({ fact, className, headerLevel }: Props) => {
  if (!fact) return null;

  return (
    <div
      className={cx(
        cs.fact,
        {
          [cs.withIcon]: fact.icon,
        },
        className,
      )}
      key={fact.description}
    >
      <dt className={cx(cs.factCount, cs[`headerLevel${headerLevel}`])}>
        {fact.count}
      </dt>

      <dd className={cs.factDescription}>{fact.description}</dd>

      {fact.icon && <Icon size={56} name={fact.icon} className={cs.factIcon} />}
    </div>
  );
};
