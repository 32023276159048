import * as React from 'react';

export const useWindowEventListener = (eventName: any, handler: any) => {
  const savedHandler = React.useRef(() => null);

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !window ||
      !savedHandler ||
      !savedHandler.current
    ) {
      return () => null;
    }

    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    const eventListener = (event: any) => savedHandler.current(event);

    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName]);
};

export const useWindowResizeEventListener = (handler: any) => {
  const currentWidth = React.useRef(0);

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    currentWidth.current = window.outerWidth;
  }, []);

  // In mobile Safari resize event unexpectedly being called on scroll
  const handleResize = (event: any) => {
    if (typeof window === 'undefined') {
      return;
    }
    if (window.outerWidth === currentWidth.current) {
      return;
    }

    currentWidth.current = window.outerWidth;

    handler(event);
  };

  useWindowEventListener('resize', handleResize);
};
