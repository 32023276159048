import * as React from 'react';
import cx from 'classnames';

import * as cs from './MarkdownSection.module.css';

interface Props {
  children: React.ReactNode;
  as: 'figure' | 'section';
  className?: string;
}

export const MarkdownSection = ({
  as: ComponentToRender,
  children,
  className,
  ...restProps
}: Props) => (
  <ComponentToRender className={cx(cs.root, className)} {...restProps}>
    <div className={cs.container}>{children}</div>
  </ComponentToRender>
);
