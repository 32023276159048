import * as React from 'react';
import cx from 'classnames';

import { AuthorCard } from '../AuthorCard/AuthorCard';
import { Divider } from '../Divider/Divider';
import { Show } from '../Show/Show';

import { ReactComponent as QuoteMark } from './assets/quote-mark.svg';
import { ReactComponent as QuoteMarkMobile } from './assets/quote-mark-mobile.svg';

import * as cs from './Quote.module.css';

import type { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  children: React.ReactNode;
  about?: string;
  name: string;
  logoUrl?: string;
  avatar?: IGatsbyImageData;
  themed?: boolean;
}

export const Quote = ({
  children,
  name,
  about,
  logoUrl,
  avatar,
  themed,
}: Props) => {
  return (
    <div className={cx(cs.root, { [cs.themed]: themed })}>
      <blockquote className={cs.quote}>
        {children}

        <Show when="sm">
          <QuoteMarkMobile
            className={cx(cs.icon, cs.condenced, 'ariaHidden')}
            aria-hidden
          />
        </Show>

        <Show when="md">
          <QuoteMark className={cx(cs.icon, 'ariaHidden')} aria-hidden />
        </Show>
      </blockquote>

      <Divider isDecorative className={cs.hr} />

      <figcaption className={cs.footer}>
        <div>
          <AuthorCard largeAbout name={name} about={about} avatar={avatar} />
        </div>
        {Boolean(logoUrl) && (
          <div className={cs.logoWrapper}>
            <img
              className={cx(cs.logo, 'ariaHidden')}
              alt="Author company logo"
              src={logoUrl}
              height="24"
              width="100"
              aria-hidden
              loading="lazy"
            />
          </div>
        )}
      </figcaption>
    </div>
  );
};
