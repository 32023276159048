import * as React from 'react';

import * as cs from './MarkdownEm.module.css';

interface Props {
  children?: React.ReactNode;
}

export const MarkdownEm = ({ children, ...restProps }: Props) => (
  <em className={cs.root} {...restProps}>
    {children}
  </em>
);
