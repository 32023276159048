import * as React from 'react';

import { Quote } from '../../Quote/Quote';
import { MarkdownSection } from '../MarkdownSection/MarkdownSection';

import type { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  children: React.ReactNode;
  about?: string;
  name: string;
  logoUrl?: string;
  avatar?: IGatsbyImageData;
  themed?: boolean;
}

export const MarkdownQuote = ({
  children,
  name,
  about,
  logoUrl,
  avatar,
  ...restProps
}: Props) => {
  return (
    <MarkdownSection as="figure" {...restProps}>
      <Quote name={name} about={about} logoUrl={logoUrl} avatar={avatar}>
        {children}
      </Quote>
    </MarkdownSection>
  );
};
