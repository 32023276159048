import queryString from 'query-string';

export const PARAMS_UPDATE_EVENT_ID = 'emsearchchange';
const paramsUpdateEvent =
  typeof Event !== 'undefined' && new Event(PARAMS_UPDATE_EVENT_ID);

export function parseURLParams(location: any) {
  return (
    queryString.parse(
      typeof window === 'undefined' ? location.search : window.location.search,
    ) || {}
  );
}

export const createLocationSearchString = (params: any) =>
  queryString.stringify(params);

export const routeWithParams = (route: any, params: any) => {
  const locationSearchString = createLocationSearchString(params);
  return `${route}${
    locationSearchString.length ? '?' : ''
  }${locationSearchString}`;
};

export const updateURLParams = ({ params, location }: any) => {
  if (typeof window === 'undefined') {
    return;
  }

  window.history.replaceState(
    null,
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    null,
    routeWithParams(location.pathname, params),
  );

  // @ts-expect-error TS(2345): Argument of type 'false | Event' is not assignable... Remove this comment to see the full error message
  window.dispatchEvent(paramsUpdateEvent);
};
