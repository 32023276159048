import * as culori from 'culori/require'; // eslint-disable-line import/no-unresolved

const formatColorToParse = (color: string) => {
  if (color.startsWith('oklch(')) {
    return color.replace(/^oklch\(/, 'color(--oklch ');
  }

  return color;
};

export default function isDarkColor(color: string) {
  const culoriBgColor = culori.parse(formatColorToParse(color));
  const bgLch = culori.oklch(culoriBgColor);

  return (bgLch ? bgLch.l : 1) <= 0.5;
}
