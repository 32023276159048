import * as React from 'react';

import { Link } from '../../Link/Link';
import { getTypeByHash } from '../../../config/feedbackTypes';
import { routes } from '../../../config/routes';
import { track } from '../../../utils/analytics';

interface Props {
  children?: React.ReactNode;
  href?: string;
}

export const MarkdownLink = ({ children, href, ...restProps }: Props) => (
  <Link
    to={href}
    target="_blank"
    onClick={() => {
      if (href === routes.contactUs || getTypeByHash(href)) {
        track('markdown-contact-us-click', href);
      }
    }}
    {...restProps}
  >
    {children}
  </Link>
);
