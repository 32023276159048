import * as React from 'react';
import cx from 'classnames';

import * as styles from './Divider.module.css';

const MAP_ORIENTATION_TO_CLASSES = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  'md:horizontal': 'horizontal_media_md',
  'md:vertical': 'vertical_media_md',
};

const MAP_ORIENTATION_TO_ARIA: { [key: string]: 'horizontal' | 'vertical' } = {
  horizontal: 'horizontal',
  vertical: 'vertical',
  'md:horizontal': 'horizontal',
  'md:vertical': 'vertical',
};

type OrientationOption = keyof typeof MAP_ORIENTATION_TO_CLASSES;

interface Props {
  orientation?: OrientationOption | OrientationOption[];
  isDecorative?: boolean;
  className?: string;
}

export const Divider = ({
  orientation,
  isDecorative = false,
  className,
}: Props) => {
  const realOrientation = orientation || 'horizontal';
  const orientations = Array.isArray(realOrientation)
    ? realOrientation
    : [realOrientation];

  const classes = orientations.map(
    (item) => styles[MAP_ORIENTATION_TO_CLASSES[item]],
  );

  return (
    <hr
      className={cx(styles.root, ...classes, className, {
        ariaHidden: isDecorative,
      })}
      aria-orientation={MAP_ORIENTATION_TO_ARIA[orientations[0]]}
      aria-hidden={isDecorative ? true : false}
    />
  );
};
