type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export function track(eventName: string, label?: string) {
  if (
    typeof window === 'undefined' ||
    typeof window.dataLayer === 'undefined'
  ) {
    return;
  }

  window.dataLayer.push({
    event: 'customEventToGA',
    eventName: eventName,
    eventLabel: label,
  });
}

export function getGAUserId() {
  // I found two ways to get client_id in GA4.
  //
  // 1. From gaGlobal object `return (window as any)?.gaGlobal?.vid as string;`
  // 2. From cookie
  //
  // Both options are undocumented so it is hard to say which one is better. But people use the second option for a long time
  // so it seems a bit more reliable

  for (const cookie of document.cookie.split(';')) {
    const splitCookie = cookie.split('=');
    const key = (splitCookie[0] || '').trim();

    if (key === '_ga') {
      const elements = (splitCookie[1] || '').split('.');
      if (elements.length > 2) {
        return elements.slice(-2).join('.');
      }
    }
  }
}
