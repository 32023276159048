import * as React from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';

import { WrapList } from '../WrapList/WrapList';
import { Button } from '../Button/Button';

import * as cs from './TagList.module.css';

interface Props {
  entries: CustomTypes.Tags;
  mainVariant?: string;
  className?: string;
  hideSecondaryOnMobile?: boolean;
}

export const TagList = ({
  entries,
  mainVariant,
  hideSecondaryOnMobile,
  className,
}: Props) => (
  <WrapList<CustomTypes.Tag>
    entries={entries}
    getKey={(entry) => entry.label}
    renderItem={(entry) => (
      <Button
        size="sm"
        variant={entry.type === mainVariant ? 'solid' : 'translucent'}
        theme="current_text"
        onClick={(event) => {
          event.preventDefault();

          if (!entry.to || typeof window === 'undefined') {
            return;
          }

          navigate(entry.to.replace(window.location.pathname, ''));
        }}
        type={entry.to ? 'button' : 'tag'}
        className={classNames(cs.button, {
          [cs.hideOnMobile]:
            hideSecondaryOnMobile && entry.type !== mainVariant,
        })}
      >
        {entry.label}
      </Button>
    )}
    columnGap={4}
    rowGap={8}
    className={className}
  />
);
