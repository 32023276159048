import * as React from 'react';
import cx from 'classnames';

import * as cs from './Show.module.css';

interface Props {
  children: React.ReactNode;
  when: 'sm' | 'md';
}

export const Show = ({ children, when }: Props) => {
  return <div className={cx(cs.root, cs[when])}>{children}</div>;
};
