import * as React from 'react';
import * as culori from 'culori';
import cx from 'classnames';

import isDarkColor from '../../../utils/isDarkColor';
import { ThemeSentinel } from '../../ThemeSentinel/ThemeSentinel';

import * as styles from './MarkdownWideSection.module.css';

interface Props {
  children: React.ReactNode;
  background?: string;
  secondary?: string;
  primary?: string;
  src?: string;
  mobileSrc?: string;
  customClassName?: string;
}

let counter = 0;

function getSectionId() {
  return `sec-${counter++}`;
}

function setTransparency(color: string, amount: number): string {
  const culoriColor = culori.parse(color);
  const rgb = culori.rgb(culoriColor);
  if (!rgb) return color;
  const rgbaColor = culori.rgb({
    mode: 'rgb',
    r: rgb.r,
    g: rgb.g,
    b: rgb.b,
    alpha: amount / 100,
  });

  return culori.formatRgb(rgbaColor);
}

export const MarkdownWideSection = ({
  children,
  background,
  secondary,
  primary,
  src,
  mobileSrc,
  customClassName,
}: Props) => {
  const [sectionId] = React.useState(() => getSectionId());

  const bgColorIsDark = isDarkColor(background || '#fff');

  const style = React.useMemo(() => {
    const result = {};
    const secondaryColors = secondary && {
      '--color-theme-text': secondary,
      '--secondary-color-12': setTransparency(secondary, 12),
      '--secondary-color-20': setTransparency(secondary, 20),
    };
    const primaryColor = primary && { '--color-theme-title': primary };
    const bgURL = src && { '--bg-url': `url(${src})` };
    const mobileBgURL = mobileSrc && {
      '--mobile-bg-url': `url(${mobileSrc})`,
    };
    const bg = background && {
      '--color-theme-background': background,
      '--color-theme-background-48': setTransparency(background, 48),
    };

    for (const piece of [
      primaryColor,
      secondaryColors,
      bg,
      bgURL,
      mobileBgURL,
    ]) {
      if (piece) {
        Object.assign(result, piece);
      }
    }

    return result;
  }, [secondary, primary, background, src, mobileSrc]);

  return (
    <section
      data-section-id={sectionId}
      className={cx(styles.card, customClassName)}
      style={style}
    >
      {bgColorIsDark && (
        <ThemeSentinel primaryColor={primary} secondaryColor={secondary} />
      )}
      {children}
    </section>
  );
};
