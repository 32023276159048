import * as React from 'react';

import { videoCodecs } from '../../config/videoCodecs';

interface Props extends React.HTMLAttributes<HTMLVideoElement> {
  sources: CustomTypes.VideoSource[];
  innerRef?: React.Ref<HTMLVideoElement>;
  muted?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  playsInline?: boolean;
  className?: string;
  width?: string | number;
  height?: string | number;
}

const renderSource = (source: CustomTypes.VideoSource) => {
  if (!source.type || !source.src) {
    return null;
  }

  return <source key={source.type} type={source.type} src={source.src} />;
};

// https://stackoverflow.com/questions/64212993/microsoft-edge-false-support-of-av1-on-canplaytype
// MS Edge has false-support of av1 (canPlayType === 'probably')
// so it tries to run an av1 source and leaves blank space instead of video.
// To prevent this, we exclude av1 source for MS Edge only.
export const Video = ({ sources = [], innerRef, ...restProps }: Props) => {
  const [isEdge, setIsEdge] = React.useState(false);

  React.useEffect(() => {
    if (
      window.navigator.userAgent.toLowerCase().includes('edg') &&
      window.navigator.platform === 'Win32'
    ) {
      setIsEdge(true);
    }
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      {...restProps}
      key={`edge-${isEdge ? 'true' : 'false'}-video-${sources[0].src}`}
      ref={innerRef}
    >
      {sources
        .filter((video) => !isEdge || video.type !== videoCodecs.av1)
        .map(renderSource)}
    </video>
  );
};

Video.displayName = 'Video';
