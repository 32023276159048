import * as React from 'react';

import { VisuallyHidden } from '../../VisuallyHidden/VisuallyHidden';

import * as styles from './MarkdownBadgeAppStore.module.css';

interface Props {
  url: string;
}

export const MarkdownBadgeAppStore = ({ url }: Props) => (
  <a
    className={styles.root}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us"
      alt="App Store badge"
      height="42"
      width="126"
      aria-hidden
      className="ariaHidden"
      loading="lazy"
    />

    <VisuallyHidden>Download on the App Store</VisuallyHidden>
  </a>
);
