import * as React from 'react';

import * as styles from './MarkdownColumn.module.css';

interface Props {
  children: React.ReactNode;
  sizeRatio?: number;
}

export const MarkdownColumn = ({ children, sizeRatio }: Props) => {
  const style = React.useMemo(() => {
    if (!sizeRatio) {
      return {};
    }

    return {
      '--column-size': sizeRatio,
      '--column-basis': '1px',
    } as React.CSSProperties;
  }, [sizeRatio]);

  return (
    <div style={style} className={styles.column}>
      {children}
    </div>
  );
};
