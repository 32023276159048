import * as React from 'react';
import cx from 'classnames';

import * as cs from './MarkdownFigure.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export interface ExternalProps extends Props {
  align: 'max' | 'content';
}

export const MarkdownFigure = ({
  children,
  className,
  ...restProps
}: Props) => (
  <figure className={cx(cs.root, className)} {...restProps}>
    {children}
  </figure>
);
