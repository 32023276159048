import * as React from 'react';
import cx from 'classnames';

import {
  Clickable,
  type Props as ClickableProps,
} from '../Clickable/Clickable';

import * as cs from './Button.module.css';

interface Props extends Omit<ClickableProps, 'type'> {
  size: 'sm' | 'md' | 'lg' | 'xl';
  variant: 'solid' | 'translucent';
  theme: 'current' | 'current_text' | 'primary' | 'inverted';
  hoverable?: boolean;
  type?: 'button' | 'submit' | 'reset' | 'tag';
}

export const Button = ({
  children,
  size,
  variant,
  theme,
  className,
  type = 'button',
  hoverable,
  disabled,
  ...restProps
}: Props) => {
  const classNameProp = cx(
    cs.root,
    cs[`size_${size}`],
    cs[`variant_${variant}`],
    cs[`theme_${theme}`],
    className,
    {
      [cs.hoverable]: hoverable || type !== 'tag',
      [cs.disabled]: disabled,
    },
  );

  if (type === 'tag') {
    return (
      <div {...restProps} className={classNameProp}>
        {children}
      </div>
    );
  }

  return (
    <Clickable
      {...restProps}
      disabled={disabled}
      type={type}
      className={classNameProp}
    >
      {children}
    </Clickable>
  );
};
