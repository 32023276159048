import * as React from 'react';

import { BlogPostCard } from '../../BlogPostCard/BlogPostCard';
import { EventCard } from '../../EventCard/EventCard';
import { Icon } from '../../Icon/Icon';

import * as cs from './MarkdownReference.module.css';

import type { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  slug: string;
  title: string;
  createdAt?: string;
  dateISO?: string;
  theme?: object;
  coverImage?: IGatsbyImageData;
  bgImage?: IGatsbyImageData;
  bgColorOverflow?: boolean;
  isBgFullSize?: boolean;
  randomColorIndex?: number;
  type?: 'event' | 'blogPost';
  dateStart?: string;
  dateStartISO?: string;
  eventTitle?: string;
  city?: string;
  dateStartMonth?: string;
  dateStartDay?: string;
  dateEndMonth?: string;
  dateEndDay?: string;
  slides?: {
    slides_url?: string;
  };
  talk?: {
    audio_urls?: string[];
    video_url?: string;
    live_url?: string;
  };
}

export const MarkdownReference = ({
  slug,
  title,
  createdAt,
  dateISO,
  theme,
  coverImage,
  bgImage,
  bgColorOverflow,
  isBgFullSize,
  randomColorIndex,
  type,
  dateStart,
  dateStartISO,
  eventTitle,
  city,
  dateStartMonth,
  dateStartDay,
  dateEndMonth,
  dateEndDay,
  slides,
  talk,
}: Props) => {
  const cover = coverImage
    ? {
        childImageSharp: { gatsbyImageData: coverImage },
        publicURL: '',
      }
    : null;

  const bg = bgImage
    ? {
        childImageSharp: { gatsbyImageData: bgImage },
        publicURL: '',
      }
    : null;

  return (
    <div className={cs.root}>
      {type === 'blogPost' && (
        <BlogPostCard
          size="sm"
          slug={slug}
          title={title}
          date={createdAt}
          dateISO={dateISO}
          theme={theme}
          coverImage={cover}
          bgImage={bg}
          bgColorOverflow={bgColorOverflow}
          isBgFullSize={isBgFullSize}
          randomColorIndex={randomColorIndex}
          details={
            <div className={cs.details}>
              Read also <Icon name="separator" size={14} className={cs.wave} />
            </div>
          }
        />
      )}
      {type === 'event' && (
        <EventCard
          size="sm"
          slug={slug}
          title={title}
          theme={theme}
          coverImage={cover}
          bgImage={bg}
          bgColorOverflow={bgColorOverflow}
          randomColorIndex={randomColorIndex}
          event={eventTitle}
          slides={slides?.slides_url}
          audio={talk?.audio_urls}
          video={talk?.video_url}
          live={talk?.live_url}
          city={city}
          dateStart={dateStart || ''}
          dateStartISO={dateStartISO || ''}
          dateStartMonth={dateStartMonth || ''}
          dateStartDay={dateStartDay || ''}
          dateEndMonth={dateEndMonth}
          dateEndDay={dateEndDay}
          details={
            <div className={cs.details}>
              See also <Icon name="separator" size={14} className={cs.wave} />
            </div>
          }
        />
      )}
    </div>
  );
};
