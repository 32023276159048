import * as React from 'react';
import cx from 'classnames';

import * as styles from './MarkdownInset.module.css';

interface Props {
  children: React.ReactNode;
  className: string;
}

export const MarkdownInset = ({ children, className, ...restProps }: Props) => (
  <blockquote className={cx(styles.root, className)} {...restProps}>
    {children}
  </blockquote>
);
