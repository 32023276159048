import * as React from 'react';
import cx from 'classnames';

import * as cs from './MarkdownMediaGroup.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  mode?: 'grow' | 'shrink';
  adaptive?: boolean;
}

export const MarkdownMediaGroup = ({
  children,
  className,
  mode = 'grow',
  adaptive = false,
}: Props) => (
  <div
    className={cx(cs.root, className, cs[`mode_${mode}`], {
      [cs.adaptive]: adaptive,
    })}
  >
    {children}
  </div>
);
