import * as culori from 'culori/require'; // eslint-disable-line import/no-unresolved

import { randomCardsColorVariables } from '../config/colors';
import {
  primaryFields,
  secondaryFields,
  opacityRange,
  withBgOverflowTheme,
} from '../config/theme';
import isDarkColor from './isDarkColor';

const fixRgb = (value: any) => Math.round((25500 * value) / 100);

const formatColorToParse = (color: any) => {
  return color;
};

function getColorOpacity(colorLightness: any, bgColor: any) {
  const isDarkBg = isDarkColor(bgColor);
  const [min, max] = isDarkBg ? opacityRange.dark : opacityRange.light;
  const point = isDarkBg ? 1 - colorLightness : colorLightness;
  const opacity = (max - min) * point + min;
  return `${(opacity * 100).toFixed(2)}%`;
}

function getOverride(color: any, fields: any) {
  if (!color) return {};

  const colorEntries = fields.map((field: any) => [field, color]);

  return Object.fromEntries(colorEntries);
}

export function themeTransformer(
  theme: any,
  inversed: boolean,
  isDefault = true,
  defaultTheme?: any,
) {
  const fields = Object.keys(defaultTheme || theme);

  const transparentBg = theme.background === 'transparent';
  let whiteBg = transparentBg && !inversed;
  if (!transparentBg) {
    const culoriBgColor = culori.parse(formatColorToParse(theme.background));
    const hex = culori.formatHex(culoriBgColor);
    if (hex) {
      whiteBg = ['#ffffff', '#fff', '#FFFFFF', '#FFF'].includes(hex);
    }
  }

  const colors = fields.reduce((acc, field) => {
    let value = theme[field];

    if (value === 'initial') {
      if (field !== 'logo') {
        throw new Error(
          `'initial' value should only be used for logo theming. But being used for '${field}'`,
        );
      }

      return { ...acc, [field]: { color: value } };
    }

    if (value === 'transparent') {
      value = 'rgb(255 255 255 / 0%)';
    }

    const culoriColor = culori.parse(formatColorToParse(value));
    const rgbColor = culori.rgb(culoriColor);
    const oklchColor = culori.oklch(culoriColor);

    const bgColor = transparentBg ? 'rgb(255 255 255 / 0%)' : theme.background;
    const bgOpacity = transparentBg ? '0%' : '100%';

    let color = {
      color: '255 255 255',
      oklchColor: '100% 0 0',
      opacity: '0%',
    };

    if (rgbColor && oklchColor) {
      const { r, g, b } = rgbColor;
      const { l, c, h } = oklchColor;

      color = {
        color: [fixRgb(r), fixRgb(g), fixRgb(b)].join(' '),
        oklchColor: [
          `${(l * 100).toFixed(4)}%`,
          c.toFixed(4),
          (h || 0).toFixed(4),
        ].join(' '),
        opacity:
          field === 'background' ? bgOpacity : getColorOpacity(l, bgColor),
      };
    }

    return { ...acc, [field]: color };
  }, {});
  return {
    colors,
    meta: { whiteBg, isDefault },
  };
}

function getColorVariables(key: any, { color, oklchColor, opacity }: any) {
  const rgbKey = `--rgb-theme-${key}`;
  const oklchKey = `--oklch-theme-${key}`;
  const opacityKey = `--opacity-theme-${key}`;

  let variables = {
    [rgbKey]: color,
    [oklchKey]: oklchColor,
    [opacityKey]: opacity,
  };

  if (key === 'logo') {
    const isInitial = color === 'initial';
    const switchVariable = {
      [`--switch-theme-${key}`]: isInitial ? 0 : 1,
    };

    variables = isInitial
      ? switchVariable
      : { ...variables, ...switchVariable };
  }

  return variables;
}

export function themeToVariables(
  theme: any,
  withBgColorOverflow: boolean | null,
  randomColorIndex = 0,
) {
  if (!theme) return null;

  const colors = Object.entries(theme.colors).reduce((acc, [key, value]) => {
    const variables = getColorVariables(key, value);

    return { ...acc, ...variables };
  }, {});

  let defaultThemeWithBgVariablesColors = {};
  let defaultThemeWithBgVariablesMeta = {};
  if (withBgColorOverflow && theme.meta.isDefault) {
    const defaultThemeWithBg = themeTransformer(withBgOverflowTheme, true);

    defaultThemeWithBgVariablesColors = Object.entries(
      defaultThemeWithBg.colors,
    ).reduce((acc, [key, value]) => {
      const variables = getColorVariables(key, value);
      return { ...acc, ...variables };
    }, {});

    defaultThemeWithBgVariablesMeta = defaultThemeWithBg.meta;
  }

  return {
    ...theme,
    meta:
      withBgColorOverflow && theme.meta.isDefault
        ? defaultThemeWithBgVariablesMeta
        : theme.meta,
    colors: {
      ...(withBgColorOverflow && theme.meta.isDefault
        ? defaultThemeWithBgVariablesColors
        : colors),
      ...(withBgColorOverflow
        ? randomCardsColorVariables[randomColorIndex]
        : {}),
    },
  };
}

export function themeResolver(defaultTheme: any, useOnlyDefaultTheme: any) {
  return async function resolver(source: any) {
    let theme = defaultTheme;
    let isDefault = true;

    if (!useOnlyDefaultTheme) {
      const { primary, secondary, ...themeOverride } = source.theme || {};

      const primaryOverride = getOverride(primary, primaryFields);
      const secondaryOverride = getOverride(secondary, secondaryFields);

      if (
        Object.keys(primaryOverride).length > 0 ||
        Object.keys(secondaryOverride).length > 0
      ) {
        isDefault = false;
      }

      theme = {
        ...defaultTheme,
        ...primaryOverride,
        ...secondaryOverride,
        ...themeOverride,
      };
    }

    return await themeTransformer(theme, false, isDefault, defaultTheme);
  };
}
