import { routes } from './routes';

export enum FeedbackType {
  General = 'general',
  Lead = 'lead',
  Content = 'content',
}

export const feedbackTypeAnchors: Record<FeedbackType, string> = {
  [FeedbackType.General]: 'other',
  [FeedbackType.Lead]: 'hire-us',
  [FeedbackType.Content]: 'feedback',
};

/*
A map of default feedback types for each route. If rule is not specified then use default feedback type (General)
*/
const DEFAULT_FEEDBACK_FOR_ROUTE: RoutesConfig = {
  [FeedbackType.Content]: [
    routes.blog,
    routes.events,
    routes.devPropulsionLabs,
  ],
  [FeedbackType.Lead]: [
    routes.devtools,
    routes.forStartups,
    routes.clients,
    routes.services,
    routes.products,
    routes.railsStartupStack,
  ],
};

type RoutesConfig = {
  [key in FeedbackType]?: string[];
};

export const getTypeByHash = (hash = '') => {
  hash = hash.replace('#', '');
  const t = Object.entries(feedbackTypeAnchors).find(([, v]) => v === hash);
  if (t) {
    return t[0] as FeedbackType;
  }
};

export const isFeedbackTypeHash = (hash = '') => {
  return !!getTypeByHash(hash);
};

export const getFeedbackType = (pagePath: string): FeedbackType => {
  const byHash = getTypeByHash(
    typeof window !== 'undefined' ? window.location.hash : '',
  );
  if (byHash) {
    return byHash;
  }

  for (const [category, rules] of Object.entries(DEFAULT_FEEDBACK_FOR_ROUTE)) {
    if (rules.some((rule) => pagePath.startsWith(rule))) {
      return category as FeedbackType;
    }
  }

  return FeedbackType.General;
};
