import React from 'react';
import { MDXProvider } from '@mdx-js/react';

import { components } from '../components/Markdown/compiler';

interface Props {
  element: React.ReactNode;
}

const MDXWrapper = ({ element }: Props) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};

export const WrapElement = ({ element }: Props) => {
  return <MDXWrapper element={element} />;
};
