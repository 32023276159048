import * as React from 'react';

import { Link } from '../../Link/Link';
import { MarkdownOL } from '../MarkdownOL/MarkdownOL';
import { MarkdownLI } from '../MarkdownLI/MarkdownLI';
import { MarkdownSection } from '../MarkdownSection/MarkdownSection';
import { routes } from '../../../config/routes';

import * as cs from './MarkdownPostSeries.module.css';

interface Props {
  series: {
    title: string;
    slug: string;
    current?: boolean;
  }[];
}

export const MarkdownPostSeries = ({ series, ...restProps }: Props) => (
  <MarkdownSection as="section" {...restProps}>
    <h2 className={cs.title}>Other parts:</h2>

    <MarkdownOL>
      {series.map((item) => (
        <MarkdownLI key={item.slug}>
          {item.current ? (
            item.title
          ) : (
            <Link to={routes.blogPost(item.slug)}>{item.title}</Link>
          )}
        </MarkdownLI>
      ))}
    </MarkdownOL>
  </MarkdownSection>
);
