import * as React from 'react';

import * as styles from './MarkdownUL.module.css';

interface Props {
  children?: React.ReactNode;
}

export const MarkdownUL = ({ children, ...restProps }: Props) => (
  <ul className={styles.root} {...restProps}>
    {children}
  </ul>
);
