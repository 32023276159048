module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://evilmartians.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"askConsentAtCookiePolicyUpdate":true,"enableLgpd":true,"enableUspr":true,"lgpdAppliesGlobally":false,"ccpaAcknowledgeOnDisplay":true,"consentOnContinuedBrowsing":false,"cookiePolicyInOtherWindow":true,"countryDetection":true,"enableCcpa":true,"enableRemoteConsent":true,"gdprAppliesGlobally":false,"invalidateConsentWithoutLog":true,"lang":"en","perPurposeConsent":true,"siteId":1232610,"cookiePolicyId":54788789,"cookiePolicyUrl":"/cookies","privacyPolicyUrl":"/privacy","privacyPolicyNoticeAtCollectionUrl":"/privacy#notice_at_collection","banner":{"acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#000000","acceptButtonDisplay":true,"applyStyles":false,"backgroundColor":"#FFFFFF","brandBackgroundColor":"#FFFFFF","brandTextColor":"#000000","closeButtonDisplay":false,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#00000014","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSize":"12.5px","listPurposes":true,"logo":"/images/martian-cookies.svg","position":"float-bottom-right","rejectButtonCaptionColor":"#000000","rejectButtonColor":"#00000014","rejectButtonDisplay":true,"textColor":"#000000","acceptButtonCaption":"Accept cookies","content":"Humans! We and selected third parties come in peace and bring cookies—for “experience enhancement” and “measurement purposes” only. We don’t sell your personal information. You can deny, or withdraw your consent at any time. In such case, some  features may not work. Please read our <b><a href=\"/cookies\" target=\"_blank\">Cookie Policy</a></b>.","rejectButtonCaption":"Deny","customizeButtonCaption":"Customize"}},"googleTagManagerOptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T4M5GLB","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
