import { getCalApi } from '@calcom/embed-react';
import { useEffect, useState } from 'react';

import { useSiteMetadata } from './useSiteMetadata';
import { track } from './analytics';

const CalComOptions = {
  styles: { branding: { brandColor: '#000000' } },
  hideEventTypeDetails: false,
  layout: 'month_view',
} as const;

type PrefillProps = {
  name: string;
  email: string;
  notes: string;
};

type Options = {
  link?: string;
  props?: PrefillProps;
};

export function useCalCom({ props, link }: Options = {}) {
  const [loaded, setLoaded] = useState(false);
  const { calCom } = useSiteMetadata();
  const calComLink = link || calCom;

  useEffect(() => {
    const wnd = window as any as { __emCalComInitPromise: Promise<void> };
    if (!wnd['__emCalComInitPromise']) {
      wnd['__emCalComInitPromise'] = initCalCom();
    }

    wnd.__emCalComInitPromise.then(() => {
      setLoaded(true);
    });
  }, []);

  return loaded
    ? {
        'data-cal-link': getLink(calComLink, props),
      }
    : ({
        // Fallback to a link to cal.com website before script is loaded
        target: '_blank',
        to: `https://cal.com/${calComLink}`,
      } as const);
}

async function initCalCom() {
  const cal = await getCalApi();
  cal('ui', CalComOptions);
  cal('on', {
    action: 'bookingSuccessful',
    callback: (e) => {
      // Status can be ACCEPTED or CANCELLED
      const status = (e.detail?.data as any)?.booking?.status || 'unknown';
      track(`book-call-${status.toLowerCase()}`);
    },
  });
}

function getLink(calComLink: string, prefillProps?: PrefillProps) {
  if (prefillProps) {
    const params = new URLSearchParams(prefillProps);
    return `${calComLink}?${params.toString()}`;
  }

  return calComLink;
}
