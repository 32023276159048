import * as React from 'react';

import { useMedia, DESKTOP_MENU_QUERY } from './useMedia';
import { track } from './analytics';

export const useScrollTo = ({
  contactUs,
  to,
  onClick,
  trackEvent,
  trackLabel,
}: any) => {
  const isDesktop = useMedia(DESKTOP_MENU_QUERY);

  const focusForm = () => {
    const textarea = document.getElementById('contact_request[message]');

    if (textarea) {
      textarea.focus({ preventScroll: true });
    }
  };

  const scrollToElement = React.useCallback(
    (event: any) => {
      if (event) {
        event.preventDefault();
      }

      if (trackEvent || trackLabel) {
        track(trackEvent || 'link-click', trackLabel);
      }

      if (typeof window === 'undefined') {
        return;
      }

      if (to === '#') {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        const element = document.getElementById(to.replace(/^#/, ''));

        if (element) {
          const elementTop = element.getBoundingClientRect().top;
          let headerHeigthY = 0;

          if (!isDesktop) {
            const mobileHeader = document.getElementById(
              'sticky-mobile-header',
            );
            if (mobileHeader) {
              headerHeigthY = mobileHeader.getBoundingClientRect().height;
            }
          }

          const y = elementTop + window.scrollY - headerHeigthY;
          window.scrollTo({
            top: y,
            left: 0,
            behavior: 'smooth',
          });

          setTimeout(() => {
            const { top } = element.getBoundingClientRect();
            if (top > 1) {
              window.scrollTo({
                top: top + window.scrollY - headerHeigthY,
                left: 0,
                behavior: 'smooth',
              });

              if (contactUs) {
                setTimeout(() => {
                  focusForm();
                }, 200);
              }
            } else if (contactUs) {
              focusForm();
            }
          }, 1000);
        }
      }

      if (onClick) {
        onClick(event);
      }
    },
    [contactUs, isDesktop, onClick, to, trackEvent, trackLabel],
  );

  return scrollToElement;
};
