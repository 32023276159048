import * as React from 'react';
import cx from 'classnames';

import { VisuallyHidden } from '../../VisuallyHidden/VisuallyHidden';

import * as cs from './MarkdownBadgeGooglePlay.module.css';

interface Props {
  url: string;
}

export const MarkdownBadgeGooglePlay = ({ url }: Props) => (
  <a className={cs.root} href={url} target="_blank" rel="noopener noreferrer">
    <img
      className={cx(cs.image, 'ariaHidden')}
      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
      height="60"
      width="155"
      alt="Google Play badge"
      aria-hidden
      loading="lazy"
    />

    <VisuallyHidden>Get it on Google Play</VisuallyHidden>
  </a>
);
