import * as React from 'react';

import * as styles from './MarkdownStrong.module.css';

interface Props {
  children?: React.ReactNode;
}

export const MarkdownStrong = ({ children, ...restProps }: Props) => (
  <strong className={styles.root} {...restProps}>
    {children}
  </strong>
);
