import { useLayoutEffect, useState } from 'react';

export const MOBILE_QUERY = 'max-width: 767px';
export const TABLET_DESKTOP_QUERY = 'min-width: 768px';
export const DESKTOP_QUERY = 'min-width: 900px';
export const DESKTOP_MENU_QUERY = 'min-width: 1280px';
export const REDUCED_MOTION_QUERY = 'prefers-reduced-motion';

export function useMedia(query: string) {
  const [matches, setMatches] = useState(false);

  useLayoutEffect(() => {
    const onUpdateMatch = ({ matches: newMatches }: { matches: boolean }) => {
      setMatches(newMatches);
    };

    const matcher = window.matchMedia(`(${query})`);

    if (matcher.addEventListener) {
      matcher.addEventListener('change', onUpdateMatch);
    } else if (matcher.addListener) {
      matcher.addListener(onUpdateMatch);
    }

    onUpdateMatch(matcher);

    return () => {
      if (matcher.removeEventListener) {
        matcher.removeEventListener('change', onUpdateMatch);
      } else if (matcher.removeListener) {
        matcher.removeListener(onUpdateMatch);
      }
    };
  }, [query]);

  return matches;
}
