import * as React from 'react';

import * as cs from './ThemeSentinel.module.css';

interface Props {
  primaryColor?: string;
  secondaryColor?: string;
  hidden?: boolean;
}

let counter = 0;

function getSentinelId() {
  return `sentinel-${counter++}`;
}

export const ThemeSentinel = ({
  primaryColor,
  secondaryColor,
  hidden,
}: Props) => {
  const [sentinelId] = React.useState(() => getSentinelId());
  return (
    <div
      data-sentinel-id={sentinelId}
      data-primary-color={primaryColor || ''}
      data-secondary-color={secondaryColor || ''}
      data-hidden={hidden}
      data-sentinel
      className={cs.root}
    />
  );
};
