import * as React from 'react';
import cx from 'classnames';

export interface Props {
  className?: string;
}

export const MarkdownSidenoteDivider = (props: Props) => (
  <hr {...props} className={cx(props.className, 'ariaHidden')} aria-hidden />
);
