import * as React from 'react';

import * as styles from './MarkdownCaption.module.css';

interface Props {
  children: React.ReactNode;
}

export const MarkdownCaption = ({ children, ...restProps }: Props) => (
  <figcaption className={styles.root} {...restProps}>
    {children}
  </figcaption>
);
