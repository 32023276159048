export const randomCardsColorVariables = [
  {
    '--rgb-theme-background': '70 0 173',
    '--oklch-theme-background': '38.07% 0.222 286.97',
  },
  {
    '--rgb-theme-background': '144 0 101',
    '--oklch-theme-background': '43.41% 0.203 346.343',
  },
  {
    '--rgb-theme-background': '140 0 4',
    '--oklch-theme-background': '40.63% 0.181 28.43',
  },
  {
    '--rgb-theme-background': '49 88 0',
    '--oklch-theme-background': '41.56% 0.131 132.41',
  },
  {
    '--rgb-theme-background': '2 94 88',
    '--oklch-theme-background': '43.43% 0.096 187.22',
  },
  {
    '--rgb-theme-background': '0 64 111',
    '--oklch-theme-background': '36.35% 0.121 248.5',
  },
];
