import * as React from 'react';
import cx from 'classnames';

import { MarkdownHeading } from './MarkdownHeading/MarkdownHeading';
import { MarkdownBadgeAppStore } from './MarkdownBadgeAppStore/MarkdownBadgeAppStore';
import { MarkdownBadgeGooglePlay } from './MarkdownBadgeGooglePlay/MarkdownBadgeGooglePlay';
import { MarkdownQuote } from './MarkdownQuote/MarkdownQuote';
import { MarkdownCaption } from './MarkdownCaption/MarkdownCaption';
import { MarkdownConsultationCta } from './MarkdownConsultationCta/MarkdownConsultationCta';
import { MarkdownEm } from './MarkdownEm/MarkdownEm';
import { MarkdownEmbed } from './MarkdownEmbed/MarkdownEmbed';
import {
  MarkdownFigure,
  type ExternalProps as MarkdownFigureProps,
} from './MarkdownFigure/MarkdownFigure';
import { MarkdownHR } from './MarkdownHR/MarkdownHR';
import { MarkdownImage } from './MarkdownImage/MarkdownImage';
import { MarkdownInset } from './MarkdownInset/MarkdownInset';
import { MarkdownLI } from './MarkdownLI/MarkdownLI';
import { MarkdownLink } from './MarkdownLink/MarkdownLink';
import { MarkdownOL } from './MarkdownOL/MarkdownOL';
import { MarkdownIntro } from './MarkdownIntro/MarkdownIntro';
import { MarkdownSidenote } from './MarkdownSidenote/MarkdownSidenote';
import {
  MarkdownSidenoteDivider,
  type Props as MarkdownSidenoteDividerProps,
} from './MarkdownSidenoteDivider/MarkdownSidenoteDivider';
import { MarkdownPostSeries } from './MarkdownPostSeries/MarkdownPostSeries';
import { MarkdownMediaGroup } from './MarkdownMediaGroup/MarkdownMediaGroup';
import { MarkdownVideo } from './MarkdownVideo/MarkdownVideo';
import { MarkdownReference } from './MarkdownReference/MarkdownReference';
import { MarkdownStrong } from './MarkdownStrong/MarkdownStrong';
import { MarkdownUL } from './MarkdownUL/MarkdownUL';
import { MarkdownColumn } from './MarkdownColumn/MarkdownColumn';
import { MarkdownColumnsWrapper } from './MarkdownColumnsWrapper/MarkdownColumnsWrapper';
import { MarkdownWideSection } from './MarkdownWideSection/MarkdownWideSection';

import * as cs from './Markdown.module.css';

interface HrProps extends MarkdownSidenoteDividerProps {
  postNoteDivider?: boolean;
}

const components = {
  a: MarkdownLink,
  h1: (props: any) => (
    <MarkdownHeading level={1} className={cs.max} {...props} />
  ),
  h2: (props: any) => (
    <MarkdownHeading level={2} className={cs.max} {...props} />
  ),
  h3: (props: any) => (
    <MarkdownHeading level={3} className={cs.max} {...props} />
  ),
  h4: (props: any) => (
    <MarkdownHeading level={4} className={cs.max} {...props} />
  ),
  h5: (props: any) => (
    <MarkdownHeading level={5} className={cs.max} {...props} />
  ),
  h6: (props: any) => (
    <MarkdownHeading level={6} className={cs.max} {...props} />
  ),
  ul: MarkdownUL,
  ol: MarkdownOL,
  li: MarkdownLI,
  strong: MarkdownStrong,
  em: MarkdownEm,
  hr: ({ postNoteDivider, ...props }: HrProps) =>
    postNoteDivider ? (
      <MarkdownSidenoteDivider className={cs.max} {...props} />
    ) : (
      <MarkdownHR {...props} />
    ),
  blockquote: (props: any) => <MarkdownInset className={cs.max} {...props} />,
  Figcaption: MarkdownCaption,
  Figure: ({ align, ...props }: MarkdownFigureProps) => (
    <MarkdownFigure className={cx({ [cs.max]: align === 'max' })} {...props} />
  ),
  Image: MarkdownImage,
  MediaGroup: (props: any) => (
    <MarkdownMediaGroup className={cs.max} {...props} />
  ),
  Intro: (props: any) => <MarkdownIntro className={cs.max} {...props} />,
  Sidenote: (props: any) => (
    <MarkdownSidenote className={cs.aside} {...props} />
  ),
  Quote: (props: any) => <MarkdownQuote className={cs.max} {...props} />,
  Video: MarkdownVideo,
  PostSeries: (props: any) => (
    <MarkdownPostSeries className={cs.max} {...props} />
  ),
  ReferenceBlogPost: MarkdownReference,
  ReferenceEvent: MarkdownReference,
  Embed: (props: any) => <MarkdownEmbed {...props} />,
  BadgeAppStore: (props: any) => <MarkdownBadgeAppStore {...props} />,
  BadgeGooglePlay: (props: any) => <MarkdownBadgeGooglePlay {...props} />,
  Column: (props: any) => (
    <MarkdownColumn sizeRatio={props.size_ratio} {...props} />
  ),
  ColumnsWrapper: (props: any) => <MarkdownColumnsWrapper {...props} />,
  ConsultationCta: (props: any) => <MarkdownConsultationCta {...props} />,
  WideSection: (props: any) => (
    <MarkdownWideSection
      className={cs.max}
      customClassName={props.custom_class}
      {...props}
    />
  ),
};

export { components };
