import * as React from 'react';
import cx from 'classnames';
import { Link as GatsbyLink } from 'gatsby';

import { track } from '../../utils/analytics';
import { routes } from '../../config/routes';
import { useScrollTo } from '../../utils/useScrollTo';
import { isFeedbackTypeHash } from '../../config/feedbackTypes';

import * as cs from './Clickable.module.css';

export interface Props
  extends React.HTMLAttributes<
    HTMLDivElement | HTMLButtonElement | HTMLAnchorElement
  > {
  to?: string;
  activeClassName?: string;
  partiallyActive?: boolean;
  target?: '_blank' | '_self';
  innerRef?: React.Ref<any>;
  disabled?: boolean;
  trackEvent?: string | string[];
  trackLabel?: string;
  type?: 'button' | 'submit' | 'reset';
  rel?: string;
}

export const Clickable = ({
  children,
  to,
  className,
  activeClassName,
  partiallyActive,
  target,
  innerRef,
  disabled,
  trackEvent,
  trackLabel,
  type,
  onClick,
  ...restProps
}: Props) => {
  const scrollToElement = useScrollTo({
    contactUs: to === routes.contactUs || isFeedbackTypeHash(to),
    to,
    onClick,
    trackEvent,
    trackLabel,
  });

  const classes = cx(className, cs.root);
  const isAnchor = /^#.*/.test(to || '');
  const pathname = usePathname(isAnchor);

  const onClickWithAnalytics = (event: any) => {
    if (trackEvent || trackLabel) {
      const events = Array.isArray(trackEvent) ? trackEvent : [trackEvent];
      events.forEach((event) => track(event || 'link-click', trackLabel));
    }

    if (onClick) {
      onClick(event);
    }
  };

  if (disabled) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        ref={innerRef}
        className={cx(className, cs.root, cs.disabled)}
        onClick={onClick}
        {...restProps}
      >
        {children}
      </div>
    );
  }

  if (!to) {
    return (
      <button
        ref={innerRef}
        className={classes}
        type={type || 'button'}
        onClick={onClickWithAnalytics}
        {...restProps}
      >
        {children}
      </button>
    );
  }

  const isInternal =
    /^\/(?!\/)/.test(to) &&
    ![routes.blogFeed, routes.eventsFeed, routes.rssFeed].includes(to);
  const isAsset = Boolean(to) && to.includes('/assets/');

  if (isAnchor) {
    return (
      <a
        className={classes}
        href={pathname + to}
        ref={innerRef}
        {...restProps}
        onClick={(e) => {
          scrollToElement(e);
          history.pushState({}, '', pathname + to);
          window.dispatchEvent(
            new CustomEvent('emAnchorChange', { detail: { hash: to } }),
          );
        }}
      >
        {children}
      </a>
    );
  }

  if (isInternal && !isAsset) {
    return (
      <GatsbyLink
        className={classes}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        ref={innerRef}
        type={type === 'submit' ? type : undefined}
        {...restProps}
        onClick={onClickWithAnalytics}
      >
        {children}
      </GatsbyLink>
    );
  }

  const targetProps = target === '_blank' && {
    target,
    rel: 'noopener noreferrer',
  };

  return (
    <a
      className={classes}
      href={to}
      ref={innerRef}
      {...targetProps}
      {...restProps}
      onClick={onClickWithAnalytics}
    >
      {children}
    </a>
  );
};

// SSR-friendly pathname (value matches server on first render)
function usePathname(isAnchor: boolean) {
  const [pathname, setPathname] = React.useState('');

  React.useEffect(() => {
    setPathname(typeof window !== 'undefined' ? window.location.pathname : '');
  }, [isAnchor]);

  return pathname;
}
