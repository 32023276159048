import React, { memo, useEffect, useRef } from 'react';
import cx from 'classnames';

import * as cs from './MarkdownEmbed.module.css';

interface Props {
  className?: string;
  html: string;
  youtube?: boolean;
}

const MarkdownEmbed = ({ className, html, youtube }: Props) => {
  const rootRef = useRef(null);

  useEffect(() => {
    const fragment = document.createRange().createContextualFragment(html);
    if (rootRef && rootRef.current) {
      (rootRef.current as HTMLElement).appendChild(fragment);
    }
  }, [html]);

  return (
    <div className={cx(className, { [cs.youtube]: youtube })} ref={rootRef} />
  );
};

const memoized = memo(MarkdownEmbed);
export { memoized as MarkdownEmbed };
