import * as React from 'react';
import cx from 'classnames';

import { FactSheet } from '../FactSheet/FactSheet';

import * as cs from './FactSheetList.module.css';

interface Props {
  facts: CustomTypes.Facts;
  max?: number;
  columns?: number;
  headerLevel?: 2 | 3;
  className?: string;
  innerRef?: React.Ref<HTMLDListElement>;
  condense?: boolean;
  tabletDesktop?: boolean;
}

export const FactSheetList = ({
  facts,
  max = 4,
  columns = 4,
  headerLevel = 3,
  className,
  innerRef,
  condense,
}: Props) => {
  const renderItem = (fact: CustomTypes.Fact | null) => (
    <FactSheet
      key={fact?.description}
      className={cs.fact}
      fact={fact}
      headerLevel={headerLevel}
    />
  );

  return (
    <dl
      className={cx(
        cs.facts,
        cs[`columns${columns}`],
        { [cs.condense]: condense },
        className,
      )}
      ref={innerRef}
    >
      {facts.slice(0, max).map(renderItem)}
    </dl>
  );
};
