import * as React from 'react';

import * as styles from './MarkdownLI.module.css';

interface Props {
  children?: React.ReactNode;
}

export const MarkdownLI = ({ children, ...restProps }: Props) => (
  <li className={styles.root} {...restProps}>
    {children}
  </li>
);
