import isAfter from 'date-fns/isAfter';
import endOfDay from 'date-fns/endOfDay';
import startOfToday from 'date-fns/startOfToday';

interface Options {
  dateStart: string;
  dateStartDay: string;
  dateStartMonth: string;
  dateEndDay?: string;
  dateEndMonth?: string;
  slice?: boolean;
}
export const renderDateInterval = ({
  dateStart,
  dateStartDay,
  dateStartMonth,
  dateEndDay,
  dateEndMonth,
  slice = false,
}: Options): string => {
  const hasDateEnd = dateEndDay && dateEndMonth && dateEndDay !== dateStartDay;

  return hasDateEnd
    ? dateStart.replace(
        ',',
        `–${
          dateStartMonth === dateEndMonth
            ? ''
            : dateEndMonth.slice(0, slice ? 3 : undefined) + ' '
        }${dateEndDay},`,
      )
    : dateStart;
};

// Has the date passed already?
export const hasPassed = (date: Date) => {
  const parsedDate = endOfDay(date);
  return isAfter(startOfToday(), parsedDate);
};

// If the dates of event are between `dateStartISO` and `dateEndISO`,
// should we consider it an upcoming (or, at the very least, active) event?
export const isUpcoming = (dateStart: Date, dateEnd: Date) => {
  const parsedDate = endOfDay(dateEnd || dateStart);
  return isAfter(parsedDate, startOfToday());
};
