import * as React from 'react';
import {
  GatsbyImage,
  getImage,
  type IGatsbyImageData,
} from 'gatsby-plugin-image';
import cx from 'classnames';

import { ThemeSentinel } from '../../ThemeSentinel/ThemeSentinel';

import * as styles from './MarkdownImage.module.css';

interface Props {
  alt?: string;
  className?: string;
  gatsbyImageData?: IGatsbyImageData;
  src?: string;
  height?: number;
  width?: number;
  plain?: boolean;
  expandToEdges?: boolean;
}

export const MarkdownImage = ({
  className,
  alt = '',
  src,
  gatsbyImageData,
  height,
  width,
  plain,
  expandToEdges,
}: Props) => {
  if (plain && src) {
    return (
      <img
        className={className}
        src={src}
        alt={alt}
        height={height}
        width={width}
        loading="lazy"
      />
    );
  }

  if (plain && gatsbyImageData) {
    const image = gatsbyImageData ? getImage(gatsbyImageData) : null;

    return image ? (
      <GatsbyImage alt={alt} className={className} image={image} />
    ) : null;
  }

  if (src) {
    return (
      <div className={cx(styles.root, className)}>
        <img
          className={cx(styles.wrapper, styles.image)}
          alt={alt}
          src={src}
          height={height}
          width={width}
          loading="lazy"
        />
      </div>
    );
  }

  const image = gatsbyImageData ? getImage(gatsbyImageData) : null;

  return (
    <div
      className={cx(styles.root, className, {
        [styles.expandToEdges]: expandToEdges,
      })}
    >
      {expandToEdges && (
        <ThemeSentinel
          hidden={true}
          primaryColor="transparent"
          secondaryColor="transparent"
        />
      )}

      {image && (
        <GatsbyImage
          alt={alt}
          className={styles.wrapper}
          image={image}
          objectFit="contain"
        />
      )}
    </div>
  );
};
