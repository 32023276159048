import React from 'react';

import { useMedia, DESKTOP_QUERY } from './useMedia';

export function useDesktopClientOnly() {
  const isDesktop = useMedia(DESKTOP_QUERY);

  const [rendered, setRendered] = React.useState(false);

  React.useEffect(() => {
    setRendered(true);
  }, []);

  return rendered && isDesktop;
}
