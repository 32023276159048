import * as React from 'react';
import cx from 'classnames';

import * as styles from './MarkdownIntro.module.css';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const MarkdownIntro = ({ className, children, ...restProps }: Props) => (
  <div className={cx(styles.root, className)} {...restProps}>
    {children}
  </div>
);
