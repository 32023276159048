import * as React from 'react';
import cx from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Icon } from '../Icon/Icon';
import { Clickable } from '../Clickable/Clickable';
import { Video } from '../Video/Video';
import { FactSheetList } from '../FactSheetList/FactSheetList';
import { themeToVariables } from '../../utils/theme';
import {
  useWindowEventListener,
  useWindowResizeEventListener,
} from '../../utils/useWindowEventListener';
import { PARAMS_UPDATE_EVENT_ID } from '../../utils/url';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';
import { useMedia, REDUCED_MOTION_QUERY } from '../../utils/useMedia';
import { useDesktopClientOnly } from '../../utils/useDesktopClientOnly';

import { ReactComponent as WaveIcon } from './assets/wave.svg';

import * as cs from './EntityCard.module.css';

export interface EntityProps {
  slug: string;
  title: string;
  description?: React.ReactNode | null;
  size: 'sm' | 'md' | 'lg' | 'lg_condenced';
  theme?: object;
  trackEvent?: string;
}

interface Props extends EntityProps {
  type: string;
  url: string;
  date?: string;
  dateISO?: string;
  details?: React.ReactNode;
  coverImage?: Queries.BlogPostPageEntryFragment['coverImage'];
  coverVideo?: Queries.BlogPostPageEntryFragment['coverVideo'];
  coverVideoLoop?: boolean;
  bgImage?: Queries.BlogPostPageEntryFragment['bgImage'];
  bgColorOverflow?: boolean | null;
  isBgFullSize?: boolean | null;
  logo?: {
    publicURL: string;
  };
  hideTitle?: boolean;
  className?: string;
  logoClassName?: string;
  facts?: CustomTypes.Facts | null;
  externalLink?: boolean;
  event?: string;
  city?: string;
  author?: string;
  attachments?: React.ReactNode | null;
  upcomingEvents?: boolean;
  coverContent?: React.ReactNode | null;
  randomColorIndex?: number;
}

export const EntityCard = ({
  type,
  url,
  title,
  date,
  dateISO,
  description,
  details,
  size,
  theme,
  coverImage,
  coverVideo,
  coverVideoLoop,
  bgImage,
  bgColorOverflow,
  isBgFullSize,
  logo,
  hideTitle = false,
  logoClassName,
  facts,
  externalLink,
  event,
  author,
  attachments,
  city,
  upcomingEvents,
  coverContent,
  randomColorIndex,
  className,
  trackEvent,
}: Props) => {
  const [factsToFit, setFactsToFit] = React.useState<CustomTypes.Facts>(
    facts || [],
  );
  const factsRef = React.useRef<HTMLDListElement>(null);
  const factsWrapperRef = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const updateFacts = React.useCallback(() => {
    if (
      factsToFit &&
      factsToFit.length > 0 &&
      factsRef &&
      factsRef.current &&
      factsWrapperRef &&
      factsWrapperRef.current
    ) {
      if (factsRef.current.clientWidth > factsWrapperRef.current.clientWidth) {
        setFactsToFit((prevFactsToFit) =>
          prevFactsToFit.slice(0, prevFactsToFit.length - 1),
        );
      }
    }
  }, [factsToFit]);

  React.useEffect(() => {
    updateFacts();
  }, [factsToFit, updateFacts]);

  const handleResize = () => {
    setTimeout(() => {
      setFactsToFit(facts || []);
      updateFacts();
    }, 0);
  };

  useWindowEventListener(PARAMS_UPDATE_EVENT_ID, handleResize);
  useWindowResizeEventListener(handleResize);
  const isReducedMotion = useMedia(REDUCED_MOTION_QUERY);
  const desktopRendered = useDesktopClientOnly();

  const { colors: themeVariables, meta: themeMeta } = themeToVariables(
    theme,
    (bgImage && bgColorOverflow) || null,
    randomColorIndex || 0,
  );

  const handleMouseEnter = React.useCallback(() => {
    if (coverVideo && videoRef && videoRef.current) {
      videoRef.current.play();
    }
  }, [coverVideo]);

  const handleMouseLeave = React.useCallback(() => {
    if (coverVideo && videoRef && videoRef.current) {
      videoRef.current.pause();
    }
  }, [coverVideo]);

  const isEvent = type === 'event';

  const bgImageData = bgImage?.childImageSharp?.gatsbyImageData
    ? getImage(bgImage.childImageSharp.gatsbyImageData)
    : null;
  const coverImageData = coverImage?.childImageSharp?.gatsbyImageData
    ? getImage(coverImage.childImageSharp.gatsbyImageData)
    : null;
  const isBgFullSizeAndSizeSm = isBgFullSize && size === 'sm';
  const currentImageData = isBgFullSizeAndSizeSm ? bgImageData : coverImageData;

  const showExternalLinkIcon = externalLink && url.charAt(0) !== '/';

  return (
    <article
      className={cx(cs.root, cs[`size_${size}`], className, {
        [cs.whiteBg]: themeMeta.whiteBg && !bgImage,
        [cs.fullWidthCover]: isBgFullSizeAndSizeSm,
      })}
      style={themeVariables}
      data-with-theme
    >
      {bgImageData && !isBgFullSizeAndSizeSm && (
        <GatsbyImage
          className={cx(cs.bgImage, cs.staticCoverImage, {
            [cs.hasVideo]: coverVideo,
            [cs.withBg]: bgColorOverflow,
          })}
          image={bgImageData}
          alt={`Background for ${title}`}
          objectFit="cover"
          aria-hidden
        />
      )}

      <div
        className={cs.link}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Clickable
          className={cs.clickable}
          to={url}
          target={externalLink ? '_blank' : '_self'}
          aria-label={title}
          trackEvent={trackEvent}
          trackLabel={trackEvent ? url : undefined}
        >
          <VisuallyHidden>{title}</VisuallyHidden>
        </Clickable>

        <header
          className={cx(cs.header, {
            [cs.headerBG]: isBgFullSize && size !== 'sm',
          })}
        >
          {Boolean(author) && (
            <div className={cx(cs.text, cs.author)}>{author}</div>
          )}
          {!hideTitle && Boolean(title) && (
            <h2 className={cs.title}>{title}</h2>
          )}
          {Boolean(date || event) && (
            <div
              className={cx(cs.dateWrapper, {
                [cs.eventDateWrapper]: isEvent,
              })}
            >
              {Boolean(!upcomingEvents && date) && (
                <time
                  className={cx(cs.date, cs.text, {
                    [cs.eventText]: isEvent,
                  })}
                  dateTime={dateISO && dateISO.split('T')[0]}
                >
                  {date}
                </time>
              )}
              {Boolean(!upcomingEvents && date && event) && (
                <WaveIcon
                  className={cx(cs.dateDivider, 'ariaHidden')}
                  aria-hidden
                />
              )}
              {Boolean(event) && (
                <span
                  className={cx(cs.text, {
                    [cs.eventText]: isEvent,
                  })}
                >
                  {event}
                </span>
              )}
              {Boolean(upcomingEvents && event && city) && (
                <WaveIcon
                  className={cx(cs.dateDivider, 'ariaHidden')}
                  aria-hidden
                />
              )}
              {Boolean(upcomingEvents && city) && (
                <span
                  className={cx(cs.text, {
                    [cs.eventText]: isEvent,
                  })}
                >
                  {city}
                </span>
              )}
            </div>
          )}
          {Boolean(description) && (
            <div className={cx(cs.description, cs.text)}>
              {description}
              {factsToFit && factsToFit.length > 0 && (
                <div className={cs.factsWrapper} ref={factsWrapperRef}>
                  <FactSheetList
                    facts={factsToFit}
                    max={2}
                    className={cs.facts}
                    columns={2}
                    innerRef={factsRef}
                  />
                </div>
              )}
            </div>
          )}
        </header>

        <div className={cs.cover}>
          {Boolean(coverImage || coverVideo || isBgFullSizeAndSizeSm) && (
            <>
              {currentImageData && (
                <GatsbyImage
                  className={cx(cs.image, cs.staticCoverImage, {
                    [cs.hasVideo]: coverVideo,
                  })}
                  image={currentImageData}
                  alt={`Cover for ${title}`}
                  objectFit="contain"
                />
              )}
              {coverVideo && desktopRendered && !isReducedMotion && (
                <Video
                  muted
                  loop={coverVideoLoop}
                  controls={false}
                  playsInline
                  className={cx(cs.image, cs.videoCover)}
                  innerRef={videoRef}
                  sources={coverVideo.map((source) => ({
                    src: source?.source?.publicURL || null,
                    type: source?.codec || null,
                  }))}
                />
              )}
            </>
          )}
          {Boolean(logo) && (
            <img
              src={logo && logo.publicURL}
              alt={`${title} logo`}
              className={cx(cs.image, cs.logo, logoClassName)}
              loading="lazy"
            />
          )}
          {Boolean(bgImage && size !== 'sm') && (
            <div
              className={cx(cs.imagePlaceholder, {
                [cs.imagePlaceholderEvent]: isEvent,
              })}
            />
          )}
          {coverContent}
        </div>

        <div
          className={cx(cs.details, cs.text, {
            [cs.withAttachments]: attachments || showExternalLinkIcon,
          })}
        >
          {details}

          {showExternalLinkIcon && (
            <Icon name="arrow" size={24} className={cs.externalLink} />
          )}

          {attachments && <div className={cs.attachments}>{attachments}</div>}
        </div>
      </div>
    </article>
  );
};
