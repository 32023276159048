import * as React from 'react';
import cx from 'classnames';

import * as styles from './MarkdownSidenote.module.css';

interface Props {
  className: string;
  children: React.ReactNode;
}

export const MarkdownSidenote = ({
  className,
  children,
  ...restProps
}: Props) => (
  <aside className={cx(styles.root, className)} role="note" {...restProps}>
    {children}
  </aside>
);
