import * as React from 'react';

import * as styles from './MarkdownOL.module.css';

interface Props {
  children?: React.ReactNode;
}

export const MarkdownOL = ({ children, ...restProps }: Props) => (
  <ol className={styles.root} {...restProps}>
    {children}
  </ol>
);
