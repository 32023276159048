import * as React from 'react';
import cx from 'classnames';

import {
  Clickable,
  type Props as ClickableProps,
} from '../Clickable/Clickable';

import * as cs from './Link.module.css';

type Props = ClickableProps;

export const Link = ({ children, className, ...restProps }: Props) => (
  <Clickable
    className={cx(cs.root, className)}
    activeClassName={cs.active}
    partiallyActive
    {...restProps}
  >
    {children}
  </Clickable>
);
