import { routes } from '../config/routes';
import { routeWithParams } from './url';
import { onlyUniqueBy } from './array';
import { capitalizeFirstLetter } from './string';

export function getEventTags({
  categories = [],
  skills = [],
  services = [],
  withEntityType,
  slice = true,
  type = 'talk',
}: any) {
  const notInCategory = (item: any) =>
    !categories.some(
      (category: any) =>
        item.shortTitle === category.title || item.title === category.title,
    );

  const notInService = (item: any) =>
    !services.some(
      (service: any) =>
        item.shortTitle === service.shortTitle ||
        item.title === service.shortTitle ||
        item.shortTitle === service.title ||
        item.title === service.title,
    );

  const filteredServices = services.filter(notInCategory);

  const filteredSkills = skills
    .filter(notInCategory)
    .filter(notInService)
    .filter(onlyUniqueBy('slug'));

  const entityType = withEntityType
    ? [
        {
          label: capitalizeFirstLetter(type),
          type: 'EntityType',
          to: routeWithParams(routes.events, {
            type,
          }),
        },
      ]
    : [];

  return [
    ...entityType,
    ...categories.map((category: any) => ({
      label: category.title,
      type: 'Category',
      to: routeWithParams(routes.events, {
        categories: category.slug,
      }),
    })),
    ...filteredServices
      .map((service: any) => ({
        label: slice ? service.shortTitle || service.title : service.title,
        shortLabel: service.shortTitle,
        type: 'Service',
        to: routeWithParams(routes.events, {
          services: service.slug,
        }),
      }))
      .slice(0, slice ? 2 : undefined),
    ...filteredSkills
      .map((skill: any) => ({
        label: slice ? skill.shortTitle || skill.title : skill.title,
        shortLabel: skill.shortTitle,
        type: 'Skill',
        to: routeWithParams(routes.events, {
          skills: skill.slug,
        }),
      }))
      .slice(0, slice ? 3 : undefined),
  ];
}
