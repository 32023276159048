import * as React from 'react';
import cx from 'classnames';

import { Video } from '../../../components/Video/Video';

import * as styles from './MarkdownVideo.module.css';

interface Props {
  alt?: string;
  className?: string;
  sources: CustomTypes.VideoSource[];
  height?: string | number;
  width?: string | number;
  muted?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
}

export const MarkdownVideo = ({
  className,
  sources,
  height,
  width,
  muted = true,
  autoPlay = true,
  loop = true,
  controls = false,
  ...restProps
}: Props) => {
  return (
    <Video
      className={cx(styles.root, className)}
      height={height}
      width={width}
      muted={muted}
      autoPlay={autoPlay}
      loop={loop}
      controls={controls}
      playsInline
      sources={sources}
      {...restProps}
    />
  );
};
