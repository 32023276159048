import * as React from 'react';
import 'focus-visible';

import { WrapElement } from './src/utils/wrapRootElement';

import type { GatsbyBrowser } from 'gatsby';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  if (
    !('IntersectionObserver' in window) ||
    typeof IntersectionObserver === 'undefined'
  ) {
    require('intersection-observer');
  }
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps,
}) => prevRouterProps?.location?.pathname !== routerProps.location.pathname;

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <WrapElement element={element} />;
};
