import * as React from 'react';

import waveIcon from './assets/wave.image.svg';

import * as styles from './MarkdownHR.module.css';

const MarkdownHR = (props: any) => (
  <div className={styles.root} role="separator" {...props}>
    <img
      src={waveIcon}
      alt="Radio wave representing wind sounds on Mars"
      aria-hidden
      className="ariaHidden"
      loading="lazy"
    />
  </div>
);

export { MarkdownHR };
